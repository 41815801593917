import React from 'react'
import Banker from "../components/Banker"
import AdminSideBar from '../components/AdminSidebar'
import DashHeader from '../components/DashHeader'


const Bank = () => {
  return (
    <div>
         <div className="instant">
        <AdminSideBar/>
        <div className="panel">
          <DashHeader/>
         
          <Banker/>
          

        </div>
      </div>
    </div>
  )
}

export default Bank