import React, { useState } from 'react';
import "../components/Login.css"
import logo from "../assets/logo.svg"
import googleicon from "../assets/gogleicon.svg"
import logline from "../assets/logline.svg"
import longline from "../assets/longline.svg"
import { Link, useNavigate } from "react-router-dom"
import { environment } from '../constants';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate()

  const handleChange = (e) => {

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    // console.log('form data: ', formData)
  };
  const submitForm = async (e) => {
    e.preventDefault();
    try {
      console.log('form data: ', formData)
      const response = await fetch(environment.api + '/account/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      console.log('login result: ', result)
      if (result.id) {
        alert("Login successful")
        localStorage.setItem("fullname", `${result.firstName} ${result.lastName}`)
        navigate("/Dashboard")
      }
      else {
        alert(result.message)
      }

    } catch (error) {
      setError(error);
    }
  }

  return (
    <div>
      <div className="sign">
        <div className="pics"></div>
        <div className="formy">
          <Link to="/"><div className="logo"><img src={logo} alt="" /></div></Link>
          <h2>Welcome back to InstantPower!</h2>
          <div className="google">

            <img src={googleicon} alt="" className='gogleicon' />

            <p>Login with Google</p>
          </div>
          <div className="login">
            <div className="logline"> <img src={logline} alt=""></img></div>
            <p className='log'>Or  login with email</p>
            <div className="logline"> <img src={logline} alt=""></img></div>

          </div>
          <form action='' className='forme' method='POST' onSubmit={submitForm}>
            <div className="email">
              <label htmlFor="mail" className='mail'>Email address</label><br />
              <input type="email" id="email" name="email" className='emailly' value={formData.email} onChange={handleChange} />
            </div>
            <div className="password">
              <label htmlFor="mail" className='mail'>Password</label><br />
              <input type="password" id="passy" name="passy" className='emailly' />
            </div>
            <div className="check">
              <label class="inline-flex items-center">
                <input type="checkbox" className="form-checkbox h-9 w-9 border-1 border-[#1B1B1B]" />
                <span class="ml-4 text[10px] text-[#1B1B1B]">Keep me logged in</span>
              </label>
              <Link to="/forgot"> <p>Forgot password?</p></Link>
            </div>
            <button
              type="submit"
              className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-full botty"
            >
              Login
            </button>
            <div className="longline"> <img src={longline} alt="" /></div>
          </form>

          <div className="uppy">
            <p>Don’t have an account? <Link to="/SignUp"><span className='up'>Sign up</span></Link> </p>
          </div>

        </div>
      </div>
    </div>
  );
};



export default Login;
