import React from 'react'
import Nav from "../components/Nav"
import map from "../assets/map.svg"
import { Link } from "react-router-dom"
import appstore from "../assets/appstore.svg";
import playstore from "../assets/playstore.svg";
import facebook from "../assets/phone3.svg";
import Footer from "./Footer"

const Contact = () => {
  return (
    <div>
      <Nav />
      <div className="contact" style={{ marginTop: "100px" }}>
        <div className="contact-text">
          <h2>Contact us</h2>
          <p>Talk to Our Team. We’re eager to hear from you and assist in any way we can. Your feedback and inquiries are important to us.</p>
        </div>
        <div className="mappy">
          <img src={map} alt="" />

        </div>

      </div>
      <div className="blueback">
        <form action='' className='formal'>
          <div className="email">
            <label htmlFor="mail" className='mailly required'>Your Name</label><br />
            <input type="text" id="email" name="email" className='emailly' placeholder='Enter Your name' />
          </div>
          <div className="email">
            <label htmlFor="mail" className='mailly'>Email Adresss</label><br />
            <input type="email" id="email" name="email" className='emailly' placeholder='Enter Your Email Address' />
          </div>
          <div className="email">
            <label htmlFor="mail" className='mailly required'>Phone number</label><br />
            <input type="number" id="email" name="email" className='emailly' placeholder='Enter Your Phone number' />
          </div>
          <div className="email">
            <label htmlFor="mail" className='mailly'>Your Message</label><br />
            <input type="email" id="email" name="email" className='ema' placeholder="Type Your message here" />
          </div>


          <button
            type="submit"
            className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-full bott"
          >
            Send
          </button>

        </form>
      </div>
      <div className="whitty">
      </div>
      <Footer />


    </div>
  )
}

export default Contact