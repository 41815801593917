import React, { useState } from 'react';

import "../components/Sign.css"
import logo from "../assets/logo.svg"
import googleicon from "../assets/gogleicon.svg"
import logline from "../assets/logline.svg"
import longline from "../assets/longline.svg"
import { Link, useNavigate } from "react-router-dom"

const Signup = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [emailExist, setEmailExist] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    name: '',
    email: '',
    address: '',
    phoneNo: '',
    password: '',
    confirmPassword: '',
    keepMeSignIn: ''
  });
  const navigate = useNavigate()
  const checkEmailExist = async (e) => {
       try {
      const email = e.target.value;
      const response = await fetch('https://instantpower-api.onrender.com/api/v1/account/check-email-exist?email=' + email, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },

      });

      const result = await response.json();
      console.log(result)
      console.log(email)
      if (result?.statusCode === 409) {
        const emailInput = document.getElementById('email');
        if (emailInput) emailInput.style.border = '1px solid red';
        alert('user with the specified email already exist')
        setEmailExist(true)
      } else {
        const emailInput = document.getElementById('email');
        if (emailInput) emailInput.style.border = '1px solid grey';
        setEmailExist(false)
      }
      console.log('signup result: ', result)
    } catch (error) {
      console.log('Error: ', error)
      if (error.status) { }
    }
  }
  const handleChange = (e) => {

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    // console.log('form data: ', formData)
  };

  const validateForm = () => {
    const newErrors = {};
    const regex = /^\d{11}$/;
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }
    if (regex.test(formData.phoneNo)){
      localStorage.setItem("usernum", formData.phoneNo)
    }
    else{
     newErrors.phoneNo = "Phone number is invalid"
    };
    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const submitForm = async () => {
    try {
      console.log('form data: ', formData)
      const response = await fetch('https://instantpower-api.onrender.com/api/v1/account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      console.log('signup result: ', result)
      if (result.id){
        alert("Registration successful")
        // const response = await fetch('https://instantpower-api.onrender.com/api/v1/notification/send-account-email', {
        // method: 'POST',
        // headers: {
        //   'Content-Type': 'application/json',
        // },
        // body: JSON.stringify({email:formData.email}),
        // });
        // const emresult = await response.json();
        // console.log('signup emresult: ', emresult)
        localStorage.setItem("fullname", `${formData.firstName} ${formData.lastName}`)
        navigate("/Dashboard")
      }
      setData(result);
    } catch (error) {
      setError(error);
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()){
      submitForm()
    }
    else{
      console.log("Form is invalid")
    }
    
  };

  return (
    <div>
      <div className="sign">
        <div className="pixxy"></div>
        <div className="formy">
          <Link to="/" >
            <div className="logo"><img src={logo} alt="" /></div>
          </Link>
          <h2>Welcome to InstantPower!</h2>
          <div className="google">

            <div className="goglecon"><img src={googleicon} alt="" className='gogleicon' /></div>

            <p>Sign Up with Google</p>
          </div>
          <div className="login">
            <div className="logline"> <img src={logline} alt=""></img></div>
            <p className='log'>Or  sign up with email</p>
            <div className="logline"> <img src={logline} alt=""></img></div>

          </div>
          <form onSubmit={handleSubmit} className='forme'>
            <div className="email">
              <label htmlFor="mail" className='mail required'>First Name</label><br />
              <input required type="text" id="firstName" name="firstName" className='emailly' value={formData.firstName} onChange={handleChange} />
            </div>
            <div className="email">
              <label htmlFor="mail" className='mail required'>Last Name</label><br />
              <input required type="text" id="lastName" name="lastName" className='emailly' value={formData.lastName} onChange={handleChange} />
            </div>
            <div className="email">
              <label htmlFor="mail" className='mail'>Adresss</label><br />
              <input value={formData.address} onChange={handleChange} type="text" id="address" name="address" className='emailly' />
            </div>
            <div className="email">
              <label htmlFor="mail" className='mail required'>Phone number</label><br />
              <input value={formData.phoneNo} onChange={handleChange} type="text" id="phoneNo" name="phoneNo" className='emailly' />
              {error && error.phoneNo && <p className="p-2 text-red-500">{error.phoneNo}</p>}
            </div>

            <div className="email">
              <label htmlFor="mail" className='mail'>Email address</label><br />
              <input value={formData.email} onChange={handleChange} onKeyUp={checkEmailExist} type="email" id="email" name="email" className={emailExist ? 'emailExist' : 'emailly'} required/>
              {emailExist && <span className='error'>User with the specified email already exist</span>}
            </div>
            <div className="password">
              <label htmlFor="mail" className='mail'>Password</label><br />
              <input value={formData.password} onChange={handleChange} type="password" id="password" name="password" className='emailly' />
            </div>
            <div className="password">
              <label htmlFor="mail" className='mail'>Confirm Password</label><br />
              <input value={formData.confirmPassword} onChange={handleChange} type="password" id="confirmPassword" name="confirmPassword" className='emailly' />
              {error && error.confirmPassword && <p className="p-2 text-red-500">{error.confirmPassword}</p>}
            </div>
            <div className="check">
              <label class="inline-flex items-center">
                <input value={formData.keepMeSignIn} onChange={handleChange} type="checkbox" className="form-checkbox h-9 w-9 border-1 border-[#1B1B1B]" id='keepMeSignIn' name='keepMeSignIn' />
                <span class="ml-4 text[10px] text-[#1B1B1B]">Keep me logged in</span>
              </label>
              <Link to="/forgot"> <p>Forgot password?</p></Link>
            </div>
            <button
              type="submit"
              className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-full botty"
            >
              Sign Up
            </button>
            <div className="longline"> <img src={longline} alt="" /></div>
          </form>

          <div className="uppy">
            <p>Already have an account?  <Link to="/Login"><span className='up'>Sign in</span></Link> </p>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Signup;
