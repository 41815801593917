import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from "./screens/Home";
import Signup from "./screens/Signup";
import Sinny from "./screens/sinny"
import Login from "./screens/Login";
import Forgot from "./screens/Forgot";
import Checkmail from "./screens/Checkmail";
import Reset from "./screens/Reset";

import Transact from "./screens/Transact";
import Meter from "./screens/Meter";
import Request from "./screens/Request";
import Change from "./screens/Change";
import Share from "./screens/Share";
import Contact from "./screens/Contact";
import Dashboard from "./screens/Dashboard";

import AdminLogin from "./screens/AdminLogin";
import AdminBuyElectric from "./screens/AdminBuyElectric";
import AdminDash from "./screens/AdminDash";
import "./App.css";
import BuyElectricity from './screens/BuyElectricity';
import AddReview from "./screens/AddReview"
import Addtransaction from './screens/Addtransaction';
import AddBonus from './screens/AddBonus';
import AddRequest from './screens/AddRequest';
import Bank from "./components/Bank"
import Bonustwo from './components/Bonustwo';
import Confirm from './components/Confirm';
import BuyToken from './screens/BuyToken';



function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/Signup' element={<Sinny />} />
      <Route path='/Login' element={<Login />} />
      <Route path='/Forgot' element={<Forgot />} />
      <Route path='/Checkmail' element={<Checkmail />} />
      <Route path='/Reset' element={<Reset />} />
      <Route path='/Contact' element={<Contact />} />
      <Route path='/Dashboard' element={<Dashboard />} />
      <Route path='/BuyElectricity' element={<BuyElectricity />} />
      <Route path='/Transact' element={<Transact />} />
      <Route path='/Meter' element={<Meter />} />
      <Route path='/Request' element={<Request />} />
      <Route path='/Change' element={<Change />} />
      <Route path='/Share' element={<Share />} />
      <Route path='/AdminLogin' element={<AdminLogin />} />
      <Route path='/AdminDash' element={<AdminDash />} />
      <Route path='/AdminBuyElectric' element={<AdminBuyElectric />} />
      <Route path='/AddReview' element={<AddReview />} />
      <Route path='/Addtransaction' element={<Addtransaction />} />
      <Route path="/AddBonus" element={<AddBonus />} />
      <Route path="/AddRequest" element={<AddRequest />} />
      <Route path="/Bank" element={<Bank />} />
      <Route path="/Bonustwo" element={<Bonustwo />} />
      <Route path="/Confirm" element={<Confirm />} />
      <Route path='/BuyToken' element={<BuyToken />} />






    </Routes>
  );
}

export default App;

