import React, { useState } from 'react'
import { environment, publicKey, override } from '../constants';
import { PaystackButton } from 'react-paystack';
import { ClipLoader } from 'react-spinners';


const Review = ({ stateValues }) => {
  const reference = Date.now().toString(32);
  const charge = 100 * 100;
  console.log('reference: ', reference)
  const [isLoading, setIsLoading] = useState(false);
  const [tokenJson, setTokenJson] = useState({});

  let {
    selectedState,
    selectedDisco,
    meterNumber,
    meterType,
    customerName,
    customerAddress,
    amount,
    email,
    phone,
  } = stateValues;

  const renderRows = () => {
    return Object.keys(tokenJson).map(key => (
      <tr key={key}>
        <td className="input-color">{key}</td>
        <td colSpan={3} className="input-color">{tokenJson[key] !== null ? tokenJson[key] === true ? 'Successful' : tokenJson[key] === false ? 'Failed' : tokenJson[key] : ''}</td>
      </tr>
    ));
  };

  const buyToken = async () => {
    setIsLoading(true);
    const stateData = JSON.parse(localStorage.getItem('stateValues'));

    console.log('stateData: ', stateData)


    const tokenData = {
      amount: amount * 100 + charge,
      biller: (selectedDisco + ' ' + meterType).toLowerCase(),
      meter: meterNumber,
      paymentRef: reference,
      phone: localStorage.getItem('usernum'),
      email: email,
      name: customerName,
      user: {
        owner: customerName,
        email: email,
        name: customerName,
        phone: phone
      },

    }
    console.log('tokenData: ', tokenData)

    fetch(environment.api + '/electricity-topup/buy-token',
      {
        method: 'POST',
        body: JSON.stringify(tokenData),
        headers: { 'Content-Type': 'application/json' }
      }).then(async (response) => {
        const token = await response.json();
        console.log('token json: ', token);
        setTokenJson(token);
        setIsLoading(false);
        localStorage.clear();
      })
  }

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const componentProps = {
    email,
    amount: amount * 100 + charge,
    reference: reference,
    metadata: {
      name: customerName,
      phone,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => {
      buyToken();
      console.log('payment is successful. Now buying token');
    },
    onClose: () => alert("Wait! You need this oil, don't go!!!!"),
  }
  if (!phone) { phone = localStorage.getItem('usernum') }
  console.log('meter type: ', meterType)

  return (
    <div>
      <div className="token-data input-color">
        {Object.keys(tokenJson).length > 0 && !isLoading && ( // Check if tokenJson is not empty
          <div>
            <h2 className="input-color">Purchase successful. The token has been sent to your Phone. You can copy it from this page too</h2>
            <table className="styled-table"> {/* Add className for styling */}
              <thead>
                <tr>
                  <th>Name</th>
                  <th colSpan="3">Value</th>
                </tr>
              </thead>
              <tbody>
                {renderRows()}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div>
        {isLoading && (
          <div className='addy pl-4'> <ClipLoader
            loading={isLoading}
            cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          </div>
        )}
      </div>
      {Object.keys(tokenJson).length === 0 && !isLoading && (

        <div className='column' >
          <div className='formix'>
            <h5 className='text-[#000] flex items-center mt-8 tetty'>Review Order</h5>
            <div className="nummy flex items-center justify-between w-4/5 m-auto">
              <div className="text-[#000]">Meter Number </div>
              <div className='text-[#000]'>{meterNumber}</div>
            </div>
            <div className="nummy flex items-center justify-between w-4/5 m-auto">
              <div className="text-[#000]">Selected Disco </div>
              <div className='text-[#000]'>{selectedDisco}</div>
            </div>
            <div className="nummy flex items-center justify-between w-4/5 m-auto">
              <div className="text-[#000]">Meter Type </div>
              <div className='text-[#000]'>{meterType}</div>
            </div>
            <div className="nummy flex items-center justify-between w-4/5 m-auto">
              <div className='text-[#000]'>Name</div>
              <div className="text-[#000]">{customerName} </div>
            </div>
            <div className="nummy flex items-center justify-between w-4/5 m-auto">
              <div className='text-[#000]'>Address</div>
              <div className="text-[#000]">{customerAddress} </div>
            </div>
            <div className="nummy flex items-center justify-between w-4/5 m-auto">
              <div className='text-[#000]'>Phone</div>
              <div className="text-[#000]">{phone} </div>
            </div>
            <div className="nummy flex items-center justify-between w-4/5 m-auto">
              <div className='text-[#000]'>Email</div>
              <div className="text-[#000]">{email} </div>
            </div>
            <div className="nummy flex items-center justify-between w-4/5 m-auto">
              <div className="text-[#000]">Phone </div>
              <div className='text-[#000]'>{phone}</div>
            </div>
            <div className="nummy flex items-center justify-between w-4/5 m-auto">
              <div className="text-[#000]">State </div>
              <div className='text-[#000]'>{selectedState}</div>
            </div>
            <h5 className='text-[#000] ml-8 mt-8 tetty'>Payment Breakdown</h5>
            <div className="nummy flex items-center justify-between w-4/5 m-auto">
              <div className="text-[#000]">Vend Amount</div>
              <div className='text-[#000]'>NGN {amount}</div>
            </div>
            <div className="nummy flex items-center justify-between w-4/5 m-auto">
              <div className="text-[#000]">Service Charge</div>
              <div className='text-[#000]'>NGN 100</div>
            </div>
            <div className="nummy flex items-center justify-between w-4/5 m-auto">
              <div className="text-[#000]">Gateway Charge</div>
              <div className='text-[#000]'>NGN 0.00</div>
            </div>
            <div className="nummy flex items-center justify-between w-4/5 m-auto">
              <div className="text-[#000]">Total</div>
              <div className='text-[#000]'>NGN {+amount + 100}</div>
            </div>
          </div>
          <div style={containerStyle}>
            <PaystackButton className="paystack-button text-[#000]" {...componentProps} />
          </div>

        </div>

      )
      }
    </div>
  );
}

export default Review