import React from 'react'
import  "../components/Dash.css"
import share from "../assets/share.svg"

import Footer from "./Footer"
import appstore from "../assets/appstore.svg";
import playstore from "../assets/playstore.svg";
import facebook from "../assets/phone3.svg";

import { FaLightbulb } from "react-icons/fa";
import Buy from '../components/Buy';
import DashHeader from '../components/DashHeader';
import SideBar from '../components/SideBar';
import Sharim from "../components/Sharim"

const Dashboard = () => {
  return (
    <div>
      <div className="instant">
        <SideBar/>
        <div className="panel">
          <DashHeader/>
          <Sharim />

     
          

        </div>
      </div>
      <div className="downloadapp">
        <div className="download">
          <h2>Download our app</h2>
          <div className="get">
            <div className="apple">
              <img src={appstore} alt="" />
            </div>
            <div className="play">
            <img src={playstore} alt="" />
            </div>
          </div>
          <div className="phone3">
            <img src={facebook} alt="" />
          </div>

          </div>
        </div>
        <Footer />

        </div>
        
    
  )
}

export default Dashboard




























