import React from 'react'

const Transcti = () => {
  return (
    <div>     
    <div className="boxe w-full">
       <div className="faint flex w-4/5 mt-8">
            <h6 className='mr-64'>Date</h6>
           <ul className='flex w-3/4 justify-between items-center'>
                <li>Order No</li>
               <li>Customer ID</li>
               <li>Amount</li>
           </ul>
       </div>
    </div>
</div>
  )
}

export default Transcti