import React from 'react'
import backarr from "../assets/backarr.svg"

const AddRev = () => {
  return (
    <div>
        <div className="backarr"><img src={backarr} alt="" /> </div>
        <div className='formix ml-0'>
       <h5 className='text-[#000] flex items-center mt-8 tetty'>Review Order</h5>
        <div className="nummy flex items-center justify-between w-4/5 m-auto">
        <div className="text-[#000]">Meter Number </div>
        <div className='text-[#000]'>0000000000</div>
        </div>
        <div className="nummy flex items-center justify-between w-4/5 m-auto">
        <div className="text-[#000]">Name </div>
        <div className='text-[#000]'>John Doe</div>
        </div>
        <div className="nummy flex items-center justify-between w-4/5 m-auto">
        <div className="text-[#000]">Meter Type </div>
        <div className='text-[#000]'>Prepaid</div>
        </div>
        <div className="nummy flex items-center justify-between w-4/5 m-auto">
        <div className="text-[#000]">Phone </div>
        <div className='text-[#000]'>09000000000</div>
        </div>
        <div className="nummy flex items-center justify-between w-4/5 m-auto">
        <div className="text-[#000]">State </div>
        <div className='text-[#000]'>Jos</div>
        </div>
        <h5 className='text-[#000] ml-8 mt-8 tetty'>Payment Breakdown</h5>
        <div className="nummy flex items-center justify-between w-4/5 m-auto">
        <div className="text-[#000]">Vend Amount</div>
        <div className='text-[#000]'>NGN 10000</div>
        </div>
        <div className="nummy flex items-center justify-between w-4/5 m-auto">
        <div className="text-[#000]">Service Charge</div>
        <div className='text-[#000]'>NGN 100</div>
        </div>
        <div className="nummy flex items-center justify-between w-4/5 m-auto">
        <div className="text-[#000]">Gateway Charge</div>
        <div className='text-[#000]'>NGN 0.00</div>
        </div>
        <div className="nummy flex items-center justify-between w-4/5 m-auto">
        <div className="text-[#000]">Total</div>
        <div className='text-[#000]'>NGN 1,100</div>
        </div>
       
      
      
       
        </div>
        <button
              type="submit"
              className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-2/5 mt-8 ml-72"
            >
              Click to Purchase
            </button>
    </div>
  )
}

export default AddRev