import React from 'react'
import backarr from "../assets/backarr.svg"
import arr from "../assets/arr.svg"
import { useNavigate } from 'react-router-dom'
// import rightarr from "../assets/rightarr.svg"

const Bonus = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
      // Navigate to the next component route
      navigate('/Bank'); // Replace '/next-component' with the actual route you want to navigate to
    };
  return (
    <div>
        <div className="wrody">
        <div className="transfer">
            <div className="backarrow"><img src={backarr} alt="" /> </div>
            <h4>Transfer bonus</h4>
         </div>
         <div className="amount">
        <label htmlFor="mail" className='mail'>Amount</label><br />
        <input type="number" id="email" name="firstName" className='emailly' placeholder='N1,000-50,000'/>
        </div>
         <div className="amount">
        <label htmlFor="mail" className='mail'>Recipient Account</label><br />
        <input type="number" id="email" name="firstName" className='emailly' placeholder='Enter 10 digits account number'/>
        </div>
         <div className="selo">
        <input type="" id="email" name="firstName" className='emailly' placeholder='Select Bank'/>
        <div className="right"><img src={arr} alt="" /> </div>
        </div>
        <div className="amount">
        <label htmlFor="mail" className='mail'>Remark</label><br />
        <input type="number" id="email" name="firstName" className='emailly' placeholder='What is this for (optional)'/>
        </div>
        <button
              type="submit"
              className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-full mb-4 mt-10"
              onClick={handleButtonClick}
            >
              Next
            </button>
        </div>
    </div>
  )
}

export default Bonus