// import React, {useState} from 'react'
// 
// import { useNavigate } from 'react-router-dom';
// import instaa from "../assets/instaa.svg";
// import twitter from "../assets/twitter.svg";
// import linkedin from "../assets/linkedin.svg";
// import liny from "../assets/liny.svg";
// import phone3 from "../assets/facebook.svg";
// import longline from "../assets/longline.svg";
// import warn from "../assets/warning.svg"
// import copy from "../assets/copy.svg"
// import logline from "../assets/logline.svg"
// import AddReview from "../screens/AddReviewReview"
// 

// const AddBuy = () => {
  

//   return (

     
    
//   )
// }

// export default AddBuy

import React from 'react'
import closeup from "../assets/closeup.svg"
import {Link} from 'react-router-dom'

const AddBuy = () => {
  return (
    <div>
        <div>
       
                        <div className="boxy">
                              <div className="disform">
               <form action='' className='formix'>
               <div className="email">
               <label htmlFor="mail" className='mail'>Meter number</label><br />
               <div className="lay flex w-full justify-between items-center">
               <input type="email" id="email" name="email" className='emale'/>
               <div className='addy w-full pl-4'>+ Add another meter</div>
              </div>
               </div>
               <div className="email">
               <label htmlFor="mail" className='mail'>Meter type</label><br />
               <input type="email" id="email" name="email" className='emaili'/>
               </div>
               <div className="email">
                 
               <label htmlFor="mail" className='mail'>State</label><br />
               <select name="" id="" className='emaili'>
                       <option value="">e.g FCT</option>
                      <option value="">Abia State</option>
                   </select>
                </div>
               <div className="email">
               <label htmlFor="mail" className='mail'>Amount</label><br />
               <input type="email" id="email" name="email" className='emaili'/>
              </div>
                <div className="email">
               <label htmlFor="mail" className='mail'>Phone number</label><br />
                <input type="email" id="email" name="email" className='emaili' placeholder='07019192961'/>
             </div>
               <div className="email">
                <label htmlFor="mail" className='mail'>Email Address</label><br />
               <input type="password" id="passy" name="passy" className='emaili' placeholder='Enter mail Address'/>
              </div>
             </form>
                <form action='' className='formix'>
                    <div className="closeup"><img src={closeup} alt="" /> </div>
              <div className="email">
                <label htmlFor="mail" className='mail'>Meter number</label><br />
               <div className="lay flex w-full justify-between items-center">
               <input type="email" id="email" name="email" className='emale'/>
               <div className='addy w-full pl-4'>+ Add another meter</div>
                </div>
               </div>
               <div className="email">
             <label htmlFor="mail" className='mail'>Meter type</label><br />
               <input type="email" id="email" name="email" className='emaili'/>
               </div>
             <div className="email">
                 
               <label htmlFor="mail" className='mail'>State</label><br />
            <select name="" id="" className='emaili'>
                       <option value="">e.g FCT</option>
                       <option value="">Abia State</option>
                   </select>
               </div>
               <div className="email">
               <label htmlFor="mail" className='mail'>Amount</label><br />
               <input type="email" id="email" name="email" className='emaili'/>
               </div>
                <div className="email">
                <label htmlFor="mail" className='mail'>Phone number</label><br />
               <input type="email" id="email" name="email" className='emaili' placeholder='07019192961'/>
               </div>
               <div className="email">
                <label htmlFor="mail" className='mail'>Email Address</label><br />
               <input type="password" id="passy" name="passy" className='emaili' placeholder='Enter mail Address'/>
             </div>
               </form>
                </div>
                <Link to="/AddReview">
               <button
                     type="submit"
                      className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-2/5 mb-4  ml-64">
                      
        
                     Proceed
                   </button>
                   </Link>
                  
                    </div>
                    
         
               
               
             
                
         
                
       
       
            
       
               
       
       
       // </div>
    </div>
  )
}

export default AddBuy