import React from 'react';
import "../components/Login.css"
import logo from "../assets/logo.svg"
import googleicon from "../assets/gogleicon.svg"
import logline from "../assets/logline.svg"
import longline from "../assets/longline.svg"
import {Link} from "react-router-dom"

const Forgot = () => {
  return (
    <div>
   <div className="sign">
    <div className="pics"></div>
    <div className="formy">
       <Link to="/"><div className="logo"><img src={logo} alt="" /></div></Link> 
        <h2>Forgot Password</h2>
        <p className='send'>Enter the email that you used to create your account so we can<br/> 
    send you a link for resetting your password</p>
       
      
        <form action='' className='forme'>
        <div className="email">
        <label htmlFor="mail" className='mail'>Email address</label><br />
        <input type="email" id="email" name="email" className='emailly'/>
        </div>
      
       
       <Link to="/Checkmail"> <button
              type="submit"
              className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-full botty"
            >
              Send
            </button>
            </Link>
        </form>

      
        
    </div>
   </div>
    </div>
  );
};

export default Forgot;
