import React, { useEffect, useState } from 'react';
import { environment } from '../constants';

const Meter = () => {
  const [meterTransactions, setMeterTransactions] = useState([]);
  const [phoneNo, setPhoneNo] = useState('');

  useEffect(() => {
    const getTransactions = () => {
      fetchTransactions(phoneNo);
    };

    if (phoneNo.length >= 11) {
      getTransactions();
    }
  }, [phoneNo]);

  const fetchTransactions = (phoneNumber) => {
    const url = environment.api + '/electricity-topup/token-history/' + phoneNumber;

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (response) => {
        const json = await response.json();
        if (json && Array.isArray(json)) {
          setMeterTransactions(json);
          console.log('Transaction list: ', json);
        }
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const handlePhoneNoChange = (event) => {

    setPhoneNo(event.target.value);
  };

  const handleSubmit = () => {
    if (phoneNo.length >= 11) {
      fetchTransactions(phoneNo);
    }
  };

  return (
    <div>
      {meterTransactions.length === 0 && !phoneNo ? (
        <div className='text-[#000] phone-container'>
          <label htmlFor="phoneNoInput">Enter Phone Number:</label>
          <input id="phoneNoInput" type="text" value={phoneNo} onChange={handlePhoneNoChange} />
          <button onClick={handleSubmit}>Submit</button>
        </div>
      ) : (
        <div>
          <div className="boxe w-full text-[#000]">
            <div className="faint flex w-full mt-8">
              <h6 className="mr-56">Meter Number</h6>
              <ul className="flex w-3/4 justify-between items-center">
                <li>Name</li>
                <li>Disco</li>
                <li>Address</li>
                <li>Last Purchased</li>
              </ul>
            </div>
          </div>
          {meterTransactions.length > 0 && (
            <div>
              {/* Display transaction list */}
              {meterTransactions.map((transaction) => (
                <div key={transaction._id.$oid}>
                  {/* Render each transaction */}
                  {/* Example: <div>{transaction.transactionId}</div> */}
                </div>
              ))}
            </div>
          )}
          {meterTransactions.length === 0 && <div>No transactions found.</div>}
        </div>
      )}
    </div>
  );
};

export default Meter;
