import React from 'react';
import "../components/Login.css"
import logo from "../assets/logo.svg"
import googleicon from "../assets/gogleicon.svg"
import logline from "../assets/logline.svg"
import longline from "../assets/longline.svg"
import {Link} from "react-router-dom"

const Forgot = () => {
  return (
    <div>
   <div className="sign">
    <div className="pics"></div>
    <div className="formy">
       <Link to="/"><div className="logo"><img src={logo} alt="" /></div></Link> 
        <h2>Check your mail</h2>
        <p className='send'>We have sent  an email with password reset information to<br/> 
kehdedaniel@gmail.com</p>
       
      
        <form action='' className='forme'>
     
      
       
       <Link to="/Reset"><button
              type="submit"
              className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-full botty"
            >
              Resend Password Reset
            </button>
            </Link> 

        </form>
        <p className='send'>Didn’t receive the mail? Check spam folder </p>        

      
        
    </div>
   </div>
    </div>
  );
};

export default Forgot;
