import React from 'react'
import logo from "../assets/logo.svg"
import { Link } from 'react-router-dom';
import light from "../assets/light.svg"
import card from "../assets/card.svg"
import meter from "../assets/meter.svg"
import message from "../assets/message.svg"
import unlock from "../assets/unlock.svg"
import logout from "../assets/logout.svg"
import shary from "../assets/shary.svg";


const SideBar = () => {
  return (
    <div className="dashboard">
    <Link to="/"><div className="loggo">
      <img className="mt-3" src={logo} alt="Logo" />
        </div>
      </Link> 
      <div className="sidebar">
   
       <div className="bar">
        <div className="ovie">
        <Link to="/Dashboard" className='light'>
            <span className="icony">
            <img src={light} alt="" />
                </span>
                <h3>Buy Electricity</h3>
        </Link>
        </div>
        <div className="ovie">
        <Link to="/Transact" className='light'>
            <span className="icony">
                <img src={card} alt="" />
                </span>
                <h3>Transactions</h3>
        </Link>
        </div>
        <div className="ovie">
        <Link to="/Meter" className='light'>
            <span className="icony">
                <img src={meter} alt="" />
                </span>
                <h3>My meter</h3>
        </Link>
        </div>
        <div className="ovie">
        <Link to="/Request" className='light'>
            <span className="icony">
                <img src={message} alt="" />
                </span>
                <h3>Request Utility bill</h3>
        </Link>
        </div>
        <div className="ovie">
        <Link to="/Change" className='light'>
            <span className="icony">
                <img src={unlock} alt="" />
                </span>
                <h3>Change Password</h3>
        </Link>
        </div>
        <div className="ovie">
        <Link to="/Share" className='light'>
            <span className="icony">
                <img src={shary} alt="" />
                </span>
                <h3>Share</h3>
        </Link>
        </div>
      <div className="greeny">
        <Link to="/" className='grinny'>
            <span className="icony">
                <img src={logout} alt="" />
                </span>
                <h3 className='text-[#fff]'>Logout</h3>
        </Link>
        </div>
        </div>

  

  

   
    

    
    </div>
    </div>
  )
}

export default SideBar