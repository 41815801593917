import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import ham from "../assets/ham.svg"
import cross from "../assets/cross.png"
import { Link } from 'react-router-dom';
import light from "../assets/light.svg"
import card from "../assets/card.svg"
import meter from "../assets/meter.svg"
import message from "../assets/message.svg"
import unlock from "../assets/unlock.svg"
import logout from "../assets/logout.svg"
import shary from "../assets/shary.svg";
// import { bubble as Menu } from 'react-burger-menu';

const CustomBurgerIcon = () => <img src={ham} className='hamie'/>;
const SideMob = () => {
  return (
    <Menu className='menu' customBurgerIcon={<img src={ham} className='hamie'/>} customCrossIcon={<img src={cross} className='cross'/>}>
      {/* <Link to="/" id="home" className="menu-item" >Home</Link>
      <Link to="/Contact" id="home" className="menu-item" href="/Contact">Contact Us</Link>
      <Link id="home" className="menu-item" href="/SignUp">Become a Vendor</Link>
      <Link id="home" className="menu-item" href="/">Buy Electricity</Link> */}
      <div className="lace">
        <Link to="/Dashboard" className='light'>
            <span className="icony">
            <img src={light} alt="" />
                </span>
                <h3>Buy Electricity</h3>
        </Link>
        </div>
        <div className="lace">
        <Link to="/Transact" className='light'>
            <span className="icony">
                <img src={card} alt="" />
                </span>
                <h3>Transactions</h3>
        </Link>
        </div>
        <div className="lace">
        <Link to="/Meter" className='light'>
            <span className="icony">
                <img src={meter} alt="" />
                </span>
                <h3>My meter</h3>
        </Link>
        </div>
        <div className="lace">
        <Link to="/Request" className='light'>
            <span className="icony">
                <img src={message} alt="" />
                </span>
                <h3>Request Utility bill</h3>
        </Link>
        </div>
        <div className="lace">
        <Link to="/Change" className='light'>
            <span className="icony">
                <img src={unlock} alt="" />
                </span>
                <h3>Change Password</h3>
        </Link>
        </div>
        <div className="lace">
        <Link to="/Share" className='light'>
            <span className="icony">
                <img src={shary} alt="" />
                </span>
                <h3>Share</h3>
        </Link>
        </div>
      <div className="greeny">
        <a href="#" className='grinny'>
            <span className="icony">
                <img src={logout} alt="" />
                </span>
                <h3 className='text-[#35A349]'>Logout</h3>
        </a>
        </div>
      
    </Menu>
  );
};

export default SideMob;