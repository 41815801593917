import React from 'react'
import AdminSideBar from './AdminSidebar'
import DashHeader from './DashHeader'
import backarr from "../assets/backarr.svg"
import accesstwo from "../assets/accesstwo.svg"
// import Banker from "./Banker"
const Confirm = () => {
  return (
    <div>
          <div className="instant">
        <AdminSideBar/>
        <div className="panel">
          <DashHeader/>
{/*          
          <Banker/> */}
        <div className="firm">
        <div className="transfer">
            <div className="backarrow"><img src={backarr} alt="" /> </div>
            <h4>Confirm</h4>
         </div>
         <div className='formix'>
        <div className="nummy flex items-center justify-between w-4/5 m-auto">
        <div className="text-[#000]">Bank</div>
        <div className='text-[#000]'> <span className=''> <img src={accesstwo} alt="" /></span>Union Bank</div>
        </div>
        <div className="nummy flex items-center justify-between w-4/5 m-auto">
        <div className="text-[#000]">Account number</div>
        <div className='text-[#000]'>00000000000</div>
        </div>
        <div className="nummy flex items-center justify-between w-4/5 m-auto">
        <div className="text-[#000]">Name</div>
        <div className='text-[#000]'>John Doe</div>
        </div>
        <div className="nummy flex items-center justify-between w-4/5 m-auto">
        <div className="text-[#000]">Amount</div>
        <div className='text-[#000]'>N1000</div>
        </div>
        <div className="nummy flex items-center justify-between w-4/5 m-auto">
        <div className="text-[#000]">Transaction fee </div>
        <div className='text-[#000]'>10.00</div>
        </div>

        <div className="nummy flex items-center justify-between w-4/5 m-auto">
        <div className="text-[#000]">Details</div>
        <div className='text-[#000]'>Details written will appear here.</div>
        </div>
        <div className="nummy flex items-center justify-between w-4/5 m-auto">
        <div className="text-[#000]">Total</div>
        <div className='text-[#000]'>N1010.00</div>
        </div>
        

       
      
      
       
        </div>
        <button
              type="submit"
              className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-2/5 mt-8 ml-72"
            >
              Click to Purchase
            </button>
        </div> 
          

        </div>
      </div>
   

    </div>
  )
}

export default Confirm