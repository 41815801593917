import React from 'react';
import bell from "../assets/bell.svg";
import facy from "../assets/facy.svg";
import SideMob from "../components/SideMob"
import { useState } from 'react';
const DashHeader = () => {
  const [fullName, setFullName] = useState(localStorage.getItem("fullname") || "")
  return (
    <div className="navie">
      <SideMob />
            <div className="nave">
            
              {/* <h2>Buy electricity</h2> */}
              <div className="bell"><img src={bell} alt="" /></div>
              <p>Welcome <span style={{fontWeight:"bold", color:"black"}}>{fullName}</span></p>
              <div className="face"><img src={facy} alt="" /> </div>
              
            </div>
          </div>
  )
}

export default DashHeader