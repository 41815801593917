import React from 'react'
import "../components/Dash.css"
import share from "../assets/share.svg"

import Footer from "./Footer"
import appstore from "../assets/appstore.svg";
import playstore from "../assets/playstore.svg";
import facebook from "../assets/phone3.svg";

import { FaLightbulb } from "react-icons/fa";
import Buy from '../components/Buy';
import DashHeader from '../components/DashHeader';
import SideBar from '../components/SideBar';

const Dashboard = () => {

  return (
    <div>
      <div className="instant">
        <SideBar />
        <div className="panel">
          <DashHeader />

          <Buy />


        </div>
      </div>
      <Footer />

    </div>


  )
}

export default Dashboard



