import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import instaa from "../assets/instaa.svg";
import twitter from "../assets/twitter.svg";
import linkedin from "../assets/linkedin.svg";
import liny from "../assets/liny.svg";
import phone3 from "../assets/facebook.svg";
import longline from "../assets/longline.svg";
import warn from "../assets/warning.svg"
import copy from "../assets/copy.svg"
import logline from "../assets/logline.svg"
import Review from "../screens/Review"
import discosData from '../assets/discosData.json';
import { environment } from '../constants';
import { override } from "../constants";
import { ClipLoader } from 'react-spinners';

const Buy = () => {
  const [form, setForm] = useState(true);
  const [usernum, setUser] = useState(localStorage.getItem("usernum") || "")
  const [selectedState, setSelectedState] = useState('');
  const [selectedDisco, setSelectedDisco] = useState('');
  const [meterNumber, setMeterNumber] = useState(0);
  const [meterType, setMeterType] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [error, setError] = useState('')
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('')
  const [amount, setAmount] = useState('');
  const [stateValues, setStateValues] = useState(null);
  const [isValidatingMeter, setIsValidatingMeter] = useState(false);


  const review = () => {
    if (customerName) {
      const stateValues = {
        selectedState,
        selectedDisco,
        meterNumber,
        meterType,
        customerName,
        customerAddress,
        amount: parseFloat(amount),
        email,
        phone,
      };
      setStateValues(stateValues);

      setForm(false)
    } else {
      alert('You must validate meter before proceeding.')
    }
  }

  // Define function to handle state selection
  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedDisco('');
  };

  // Define function to handle Disco selection
  const handleDiscoChange = (event) => {
    setSelectedDisco(event.target.value);
  };
  const handleMeterTypeChange = (event) => {
    setMeterType(event.target.value);
  };
  const handleMeterNumberChange = (event) => {
    setMeterNumber(event.target.value);
  };
  const handleAmountChange = (e) => {
    setAmount(e.target.value)
  }
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }
  // Define function to handle Validate Meter button click
  const handleValidateMeter = async (e) => {
    setIsValidatingMeter(true);
    e.preventDefault();
    // Add your validation logic here
    console.log('Meter number:', meterNumber);
    console.log('Selected Disco:', selectedDisco);
    const biller = selectedDisco + ' ' + meterType;
    const body = { biller: biller.toLowerCase(), meter: meterNumber }

    const request = await fetch(environment.api + '/electricity-topup/customer-info', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(async (response) => {
      const json = await response.json()
      console.log('validation result: ', json)
      if (json?.data?.error == false && json?.status == 'success') {
        setCustomerAddress(json.data.address);
        setCustomerName(json.data.name);
        setIsValidatingMeter(false)
      }

    }).catch(error => {
      console.log('Error: ', error)
      setError(error.message)
    })


  };
  return (
    <div>
      {
        form ? (
          <>
            <div className="boxy">
              <div className=' flex flex-col items-center w-full'>
                <h2 className='text-[#000]'>Buy Electricity</h2>
                <form action='' className='formix'>

                  <div className="input-box">
                    <label htmlFor="mail" className='mail'>State</label><br />
                    <select className='emaili' value={selectedState} onChange={handleStateChange}>
                      <option value="" className='emaili'>Select a State</option>
                      {Object.keys(discosData).map(state => (
                        <option className='emaili' key={state} value={state}>{state}</option>
                      ))}
                    </select>
                  </div>
                  <div className='input-box'>
                    {selectedState && (
                      <div className='input-color'>
                        <label htmlFor="mail" className='mail'>Disco(s) for {selectedState}</label><br />
                        <select className='emaili' value={selectedDisco} onChange={handleDiscoChange}>
                          <option className='emaili' value="">Select a Disco</option>
                          {discosData[selectedState].map(disco => (
                            <option className='emaili' key={disco.shortName} value={disco.shortName}>{disco.name}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                  <div className='input-box'>
                    {selectedDisco && (
                      <div className='input-color'>
                        <label htmlFor="mail" className='mail'>Meter type for {selectedDisco}</label><br />
                        <select className='emaili' onChange={handleMeterTypeChange}>
                          <option className='emaili' value="">Select a Meter Type</option>
                          {discosData[selectedState].find(disco => disco.shortName === selectedDisco).billers.map(biller => (
                            <option className='emaili' key={biller} value={biller}>{biller}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>

                  {selectedDisco && (
                    <div className='input-box'>
                      <label htmlFor="mail" className='mail'>Meter number</label><br />
                      <div className="lay flex w-full justify-between items-center">
                        <input type="text" id='input-box' name="emaili" className='emaili black' onChange={handleMeterNumberChange} />
                        {!isValidatingMeter && (
                          <button className='addy pl-4 ' onClick={handleValidateMeter}>Validate Meter</button>
                        )}
                        {isValidatingMeter && (
                          <div className='addy pl-4'> <ClipLoader
                            loading={isValidatingMeter}
                            cssOverride={override}
                            size={50}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                          </div>
                        )}
                      </div>
                      {customerName && (
                        <div className='customer'>
                          <label htmlFor="mail" className='mail'>Customer's name: {customerName}</label><br />
                        </div>
                      )}
                      {customerAddress && (
                        <div className='customer'>
                          <label htmlFor="mail" className='mail'>Customer's Address: {customerAddress}</label><br />
                        </div>
                      )}
                    </div>

                  )}
                  {error && (
                    <div className='customer'>
                      <label htmlFor="mail" className='mail error'>{error}</label><br />

                    </div>
                  )}
                  <div className='input-box'>
                    <label htmlFor="mail" className='mail'>Amount</label><br />
                    <input type="text" id='input-box' name='input-box' className='emaili' value={amount} onChange={handleAmountChange} />
                  </div>
                  <div className='input-box'>
                    <label htmlFor="mail" className='mail'>Phone number</label><br />
                    <input type='text' id='input-box' name='input-box' className='emaili' value={usernum} />
                  </div>
                  <div className='input-box'>
                    <label htmlFor="mail" className='mail'>Email Address</label><br />
                    <input type="text" id="passy" name="passy" className='emaili' placeholder='Enter mail Address' value={email} onChange={handleEmailChange} />
                  </div>
                </form>
                <button
                  type="submit"
                  className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-2/5 mb-4"
                  onClick={review}
                >
                  Click To Purchase
                </button>
              </div>

            </div>

          </>
        ) : <Review stateValues={stateValues} />

      }

    </div >


  )
}

export default Buy