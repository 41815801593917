import React from 'react'

const Request = () => {
  return (
    <div>
        <div className="boxe">
         <form action='' className='forming'>
         <div className="email">
        <label htmlFor="mail" className='mail'>Meter type</label><br />
        <input type="email" id="email" name="email" className='emaili' placeholder='Enter Meter Number'/>
        </div>

        
        <div className="email">
        <label htmlFor="mail" className='mail'>Full Name</label><br />
        <input type="email" id="email" name="email" className='emaili'/>
        </div>
        <div className="email">
          
        <label htmlFor="mail" className='mail'>State</label><br />
        <select name="" id="" className='emaili'>
                <option value="">e.g FCT</option>
                <option value="">Abia State</option>
            </select>
        </div>
        <div className="email">
        <label htmlFor="mail" className='mail'>Phone Number</label><br />
        <input type="email" id="email" name="email" className='emaili'/>
        </div>
       
        <div className="email">
        <label htmlFor="mail" className='mail'>Email Address</label><br />
        <input type="password" id="passy" name="passy" className='emaili' placeholder='Enter mail Address'/>
        </div>
        <div className="email">
        <label htmlFor="mail" className='mail'>Address</label><br />
        <input type="email" id="email" name="email" className='emaili h-6'/>
        </div>
        <button
              type="submit"
              className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-full mb-4"
            >
              Submit Request
            </button>
        </form>

      

         </div>
    </div>
  )
}

export default Request