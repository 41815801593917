import React from 'react'
import  "../components/Dash.css"
import share from "../assets/share.svg"

import Footer from "./Footer"
import appstore from "../assets/appstore.svg";
import playstore from "../assets/playstore.svg";
import facebook from "../assets/phone3.svg";

import { FaLightbulb } from "react-icons/fa";
import Buy from '../components/Buy';
import DashHeader from '../components/DashHeader';
import AdminSideBar from '../components/AdminSidebar';
import AddBuy from "../components/AddBuy"

const AdminBuyElectric = () => {
  return (
    <div>
      <div className="instant">
        <AdminSideBar/>
        <div className="panel">
          <DashHeader/>
         
          <AddBuy/>
          

        </div>
      </div>
     pm 

        </div>
        
    
  )
}

export default AdminBuyElectric



