import React from 'react'
import AdminSideBar from '../components/AdminSidebar'
import DashHeader from '../components/DashHeader'

import accesstwo from "../assets/accesstwo.svg"
import backarr from "../assets/backarr.svg"
import rightarr from "../assets/rightarrr.svg"
import goody from "../assets/goody.svg"
import { useNavigate } from 'react-router-dom'

const Bonustwo = () => {
  const navigate = useNavigate();

    const handleButtonClick = () => {
      // Navigate to the next component route
      navigate('/Confirm'); // Replace '/next-component' with the actual route you want to navigate to
    };
  return (
    <div>
            <div className="instant">
        <AdminSideBar/>
        <div className="panel">
          <DashHeader/>
         
         <div className="wrody">
        <div className="transfer">
            <div className="backarrow"><img src={backarr} alt="" /> </div>
            <h4>Transfer bonus</h4>
         </div>
         <div className="amount">
        <label htmlFor="mail" className='mail'>Amount</label><br />
        <input type="number" id="email" name="firstName" className='emailly' placeholder='N1,000-50,000'/>
        </div>
         <div className="amount">
        <label htmlFor="mail" className='mail'>Recipient Account</label><br />
        <input type="number" id="email" name="firstName" className='emailly' placeholder='00000000000'/>
        </div>
         <div className="selo emailly">
            <div className="union">
                <img src={accesstwo} alt="" />
            </div>
        <input type="number" id="email" name="firstName" className='' placeholder='Union Bank'/>
        <div className="right"><img src={rightarr} alt="" /> </div>
        </div>
        <div className="accessy">
                <img src={goody} alt="" />
                <p>John Doe</p>
            </div>
        <div className="amount">
        <label htmlFor="mail" className='mail'>Remark</label><br />
        <input type="" id="email" name="firstName" className='emailly' placeholder='What is this for (optional)'/>
        </div>
        <button
              type="submit"
              className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-full mb-4 mt-10"
              onClick={handleButtonClick}
             
            >
              Confirm
            </button>
        </div> 
          

        </div>
      </div>
    
    </div>
  )
}

export default Bonustwo