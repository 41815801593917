import { FaLightbulb } from "react-icons/fa";
import DashHeader from '../components/DashHeader';
import SideBar from '../components/SideBar';
import Footer from "./Footer";
import appstore from "../assets/appstore.svg";
import playstore from "../assets/playstore.svg";
import facebook from "../assets/phone3.svg";
import { useState, CSSProperties, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { environment } from "../constants";
import { override } from "../constants";
import { useLocation } from 'react-router-dom';



const BuyToken = () => {
    let [loading, setLoading] = useState(true);
    const [trxref, setTrxref] = useState('');
    const [reference, setReference] = useState('');
    const [tokenJson, setTokenJson] = useState({});
    const location = useLocation();

    // window.location.reload(); 
    const getQueryParams = () => {
        const queryParams = new URLSearchParams(location.search);
        const trxrefValue = queryParams.get('trxref');

        if (trxrefValue) {
            setTrxref(trxrefValue);
        } else {
            const trxrefValue = localStorage.getItem('reference');
            setTrxref(trxrefValue);
        }

        const referenceValue = queryParams.get('reference');
        console.log('reference: ', reference,);
        console.log('trxref: ', trxref,)
        if (referenceValue) {
            setReference(referenceValue);
        }
    };

    useEffect(() => {

        // Execute getQueryParams after the component has mounted
        getQueryParams();

        const url = environment.api + '/payment/verify/' + trxref ?? '';
        const stateData = JSON.parse(localStorage.getItem('stateValues'));
        console.log('url: ', url);
        console.log('stateData: ', stateData)

        fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }).then(async (response) => {
            const json = await response.json();
            console.log('verify response: ', json);

            if (json?.status && json?.data) {
                // verification is successful
                const tokenData = {
                    amount: stateData.amount,
                    biller: (stateData.selectedDisco + ' ' + stateData.meterType).toLowerCase(),
                    meter: stateData.meterNumber,
                    paymentRef: localStorage.getItem('reference'),
                    phone: localStorage.getItem('usernum'),
                    email: stateData.email,
                    name: '',
                    user: {
                        owner: stateData.customerName,
                        email: stateData.email,
                        name: stateData.customerName,
                        phone: stateData.phone
                    },

                }
                console.log('tokenData: ', tokenData)

                fetch(environment.api + '/electricity-topup/buy-token',
                    {
                        method: 'POST',
                        body: JSON.stringify(tokenData),
                        headers: { 'Content-Type': 'application/json' }
                    }).then(async (response) => {
                        const token = await response.json();
                        console.log('token json: ', token);
                        setTokenJson(token);
                        localStorage.clear();
                    })
            }
        }).catch(error => {
            console.log('Purchase token error: ', error);
        })
        // } else {

        //     alert('No payment reference provided. visit home page and initiate a new token purchase')
        // }

    }, [location]);
    // Function to render table rows
    const renderRows = () => {
        return Object.keys(tokenJson).map(key => (
            <tr key={key}>
                <td className="input-color">{key}</td>
                <td colSpan={3} className="input-color">{tokenJson[key] !== null ? tokenJson[key] == true ? 'Successful' : tokenJson[key] == false ? 'Failed' : tokenJson[key] : ''}</td>
            </tr>
        ));
    };
    return (
        <div>
            <div className="instant">
                <SideBar />
                <div className="panel">
                    <DashHeader />
                    {Object.keys(tokenJson).length == 0 && (
                        <div className="buytoken">
                            <h1 className="input-color">Please wait!</h1>
                            <h1 className="input-color">Processing Token purchase request..</h1>
                            <div className="sweet-loading">
                                {/* <button onClick={() => setLoading(!loading)}>Toggle Loader</button>
                        <input value={color} onChange={(input) => setColor(input.target.value)} placeholder="Color of the loader" /> */}
                                <ClipLoader

                                    loading={loading}
                                    cssOverride={override}
                                    size={50}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                        </div>
                    )}
                    <div className="token-data input-color">
                        {Object.keys(tokenJson).length > 0 && ( // Check if tokenJson is not empty
                            <div>
                                <h2 className="input-color">Purchase successful. The token has been sent to your Phone. You can copy it from this page too</h2>
                                <table className="styled-table"> {/* Add className for styling */}
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th colSpan="3">Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderRows()}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>

                </div>
            </div>
            <div className="downloadapp">
                <div className="download">
                    <h2>Download our app</h2>
                    <div className="get">
                        <div className="apple">
                            <img src={appstore} alt="" />
                        </div>
                        <div className="play">
                            <img src={playstore} alt="" />
                        </div>
                    </div>
                    <div className="phone3">
                        <img src={facebook} alt="" />
                    </div>

                </div>
            </div>
            <Footer />

        </div>


    )
}
export default BuyToken;