import React, { useState, useEffect } from 'react';
import Nav from '../components/Nav';
import bell from "../assets/bell.svg";
import alarm from "../assets/alarm.svg";
import moon from "../assets/moon.svg";
import whitemoon from "../assets/whitemoon.svg";
import circle from "../assets/circle.svg";
import line from "../assets/line.svg";
import ourserve from "../assets/ourserve.svg";
import ekedc from "../assets/ekedc.svg";
import aedc from "../assets/aedc.svg";
import bedc from "../assets/bedc.svg";
import star from "../assets/star.svg";
import fineman from "../assets/fineman.svg";
import peter from "../assets/peter.svg";
import remind from "../assets/remind.svg";
import phony from "../assets/phony.svg";
import appstore from "../assets/appstore.svg";
import playstore from "../assets/playstore.svg";
import facebook from "../assets/phone3.svg";
import rollone from "../assets/rollone.png";
import rolltwo from "../assets/rolltwo.png";
import rollthree from "../assets/rollthree.png";
import rollfour from "../assets/rollfour.png";
import Footer from "../screens/Footer"
import { Link, useNavigate } from "react-router-dom"
import cusgirl from "../assets/cusgirl.jpg";
import save from "../assets/save.jpg"
import yedc from "../assets/yedc.jpeg"
import ph from "../assets/ph.jpeg"
import kedco from "../assets/kedco.jpeg"
import backgr from "../assets/backgr.jpg"
import SectionCard from '../components/SectionCard';
import { BouncyCardsFeatures } from '../components/BouncySection';
import Popup from '../components/popup';

const Home = () => {

  const [isModalOpen, setModalOpen] = useState(false);
  const [phoneNum, setPhoneNum] = useState("")
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate()
  const isValidPhoneNumber = (num) => {
    // Regular expression to match exactly 11 digits
    const regex = /^\d{11}$/;
    if (regex.test(num)) {
      localStorage.setItem("usernum", phoneNum)
      navigate("/dashboard")
    }
    else {
      setShowModal(true)
    };
  }
  const toCommission = () => {
    navigate('/Signup')
  }
  const toContact = () => {
    navigate('/Contact')
  }


  const toggleDarkMode = () => {
    const darkMode = document.querySelector('.dark-mode');
    document.body.classList.toggle('dark-mode-variables');
    darkMode.querySelector('span:nth-child(1)').classList.toggle('active');
    darkMode.querySelector('span:nth-child(2)').classList.toggle('active');

  };


  //   useEffect(() => {
  //     const darkMode = document.querySelector('.dark-mode');
  //     darkMode.addEventListener('click', toggleDarkMode);

  //     // Cleanup the event listener when the component unmounts
  //     return () => {
  //       darkMode.removeEventListener('click', toggleDarkMode);
  //     };
  //   }, []); // Empty dependency array ensures the effect runs once when the component mounts

  return (
    <div>
      <Nav isValidPhoneNumber={isValidPhoneNumber} />
      <Popup
        setShowModal={setShowModal}
        showModal={showModal}
        modalHeading={phoneNum == "" ? "Empty Field" : "Invalid Phone Number"}
        modalBodyMessage={phoneNum == "" ? "Phone number cannot be empty" : "Ensure your phone number is valid."}
      />
      <div className='w-full all' style={{}}>
        <div className='banner w-4/5 m-auto'>
          {/* <div className="sech mt-10 flex justify-end w-full">
            <div className='colo'>
         
          <img src={bell} alt="Image" className="" />
          <div className="dark-mode" onClick={toggleDarkMode}>
            <span className="material-icons-sharp" >
              light_mode
            </span>
            <span className="material-icons-sharp" >
              <img src={whitemoon} alt="" />
            </span>
          </div>
          <img src={alarm} alt="Image" className="" />
          <img src={circle} alt="Image" className="" />
          </div>
        </div> */}

          <div id='dan' className="banner2">
            <h1 className="text-[#fff] text-4xl font-bold text-center buyes">Your Electricity Payment
              Made Easy.</h1>
            <div className='w-3/5 line'><img src={line} alt="" className="" /></div>
            <p className="text-[#fff] text-xl text-center">Simplify your electricity payment and bill management at your convenience with an easy to use digital platform designed for you</p>
            <div className="text-center w-full justify-between m-auto">
              <div className='flex w-4/5 m-auto justify-between mt-4 meter items-center'>
                <div className='w-3/5 entry'>
                  <input
                    type="text"
                    className='green text[#3DBE46] w-full'
                    onChange={(e) => setPhoneNum(e.target.value)}
                    placeholder="Enter your phone number to begin"

                  />
                </div>
                <div className='w-2/6 griny'>
                  <a onClick={() => isValidPhoneNumber(phoneNum)}><button
                    type="submit"
                    className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-full z-10"
                  >
                    Buy Electricity
                  </button>
                  </a>
                </div>
              </div>

            </div>
          </div>
          {/* <Link to="/AdminLogin"><p className="become">Become a Vendor</p></Link> */}

        </div>
      </div>
      <div className="roller">
        <div className="ourservice">
          <img src={ourserve} alt="parner" className="" />
        </div>
        <div class="skew">
          <div class="skill--box">
            <img src={aedc} alt="html" />
          </div>
          <div class="skill--box">
            <img src={bedc} alt="bootstrap" />
          </div>
          <div class="skill--box">
            <img src={ekedc} alt="css" />
          </div>
          <div class="skill--box">
            <img src={rollone} alt="css" />
          </div>
          <div class="skill--box">
            <img src={rolltwo} alt="css" />
          </div>
          <div class="skill--box">
            <img src={rollthree} alt="css" />
          </div>
          <div class="skill--box">
            <img src={rollfour} alt="css" />
          </div>
          <div class="skill--box">
            <img src={yedc} alt="css" />
          </div>
          <div class="skill--box">
            <img src={ph} alt="css" />
          </div>
          <div class="skill--box">
            <img src={kedco} alt="css" />
          </div>
        </div>
      </div>

      <div className="rap">
        <div className="reap">
          <div className="bene">
            <h2>Become a Vendor and Reap Benefits</h2>
            <div className="purchase">
              <div className="star">
                <img src={star} alt="Image" className="" />
              </div>
              <p className="">Purchase electricity for up to 4 meters at once.</p>
            </div>
            <div className="purchase">
              <div className="star">
                <img src={star} alt="Image" className="" />
              </div>
              <p className="">Earn bonuses for every electricity purchase made.</p>
            </div>
            <div className="purchase">
              <div className="star">
                <img src={star} alt="Image" className="" />
              </div>
              <p className="">Withdraw bonuses directly to your bank accounts.</p>
            </div>
            <button
              onClick={toCommission}
              type="submit"
              className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded mt-4 w-1/5 start"
            >
              Get Started
            </button>
          </div>
          <div className="man">
            <img src={fineman} alt="Image" className="" />
          </div>
        </div>
      </div>
      
      <div className="support">
        <div className="suppy">
          <div className="heady">
            <div className="head">
              <img src={cusgirl} alt="Image" className="" />
            </div>
            <div className="blocky">
              <p className="">“We ensure that any information you need is served immediately by simply contacting our team”</p>
              <div className="cs">
                <p className="">Peter Fennimore</p>
                <p className="">Head of CS </p>
              </div>
            </div>
          </div>
          <div className="twenty-four">
            <h2 className="">27/4 customer support</h2>
            <p className="">Don't blame yourself; we're here to swiftly resolve your issues, 24/7, wherever you are. Your convenience is our priority.</p>
            <button
              onClick={toContact}
              type="submit"
              className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-2/5 mt-4"
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <div className="settle">
        <div className="setty">
          <div className="setup">
            <h2 className="">Set topup reminder</h2>
            <p className="">Tell us when to remind you to topup your electricity while<br /> you focus on other issue at hand</p>
            <button
              type="submit"
              className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-2/5 mt-4"
            >
              Reminder
            </button>

          </div>
          <div className="remind">
            <img src={save} alt="" />
          </div>
        </div>
      </div>
      {/* <div className="banky">
        <div className="setty">
          <div className="remind">
            <img src={phony} alt="" />
          </div>
          <div className="setpp">
            <h2 className="">Pay via bank transfer</h2>
            <p className="">Switch to bank transfers for instant, hassle-free electricity<br /> top-ups. Stay focused while we keep your power flowing<br /> smoothly.</p>
            <button
              type="submit"
              className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-2/5 mt-4"
            >
              Buy Electricity
            </button>

          </div>
        </div>
      </div> */}
      <SectionCard />

      <BouncyCardsFeatures />

      <div className="downloadapp">
        <div className="download">
          <div>
            <h2 className=''>Download our app</h2>
            <div className="flex gap-1 justify-center cursor-pointer">
              <div className="apple flex justify-center">
                <img src={appstore} alt="" />
              </div>
              <div className="play flex justify-center cursor-pointer">
                <img src={playstore} alt="" />
              </div>
            </div>
            <div className="phone3">
              {/* <img src={facebook} alt="" /> */}
            </div>
          </div>
        </div>
      </div>

      <Footer />

    </div>

  );
};

export default Home;
