import React from 'react';
import "../components/Login.css"
import logo from "../assets/logo.svg"
import googleicon from "../assets/gogleicon.svg"
import logline from "../assets/logline.svg"
import longline from "../assets/longline.svg"
import {Link} from "react-router-dom"

const AdminLogin = () => {
  return (
    <div>
   <div className="sign">
    <div className="pics"></div>
    <div className="formy">
       <Link to="/"><div className="logo"><img src={logo} alt="" /></div></Link> 
        <h2>Admin</h2>
     
   
        <form action='' className='forme'>
        <div className="email">
        <label htmlFor="mail" className='mail'>Username</label><br />
        <input type="email" id="email" name="email" className='emailly' placeholder='Please enter your username or email'/>
        </div>
        <div className="password">
        <label htmlFor="mail" className='mail'>Password</label><br />
        <input type="password" id="passy" name="passy" className='emailly' placeholder='Password' />
        </div>
        <div className="check">
        <label class="inline-flex items-center">
        <input type="checkbox" className="form-checkbox h-9 w-9 border-1 border-[#1B1B1B]"/>
        <span class="ml-4 text[10px] text-[#1B1B1B]">Keep me logged in</span>
        </label>
       <Link to="/forgot"> <p>Forgot password?</p></Link>
        </div>
        <button
              type="submit"
              className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-full botty"
            >
              Login
            </button>
            <div className="longline"> <img src={longline} alt="" /></div>
        </form>

        <div className="uppy">
            <p>Don’t have an account? <Link to="/AdminDash"><span className='up'>Sign up</span></Link> </p>
        </div>
        
    </div>
   </div>
    </div>
  );
};

export default AdminLogin;
