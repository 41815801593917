import React from 'react'
import clossy from "../assets/clossy.svg"
import searchy from "../assets/searchy.svg"
import manny from "../assets/manny.svg"
import client from "../assets/client.svg"

const Addtrans = () => {
  return (
    <div>
        <div className="subby">
            <div className="subly">
                <input type="text"  className='non' placeholder='Enter name or amount'/>
                <div className="clossy"><img src={clossy} alt="" /> </div>
            </div>
            <div className="searchy"><img src={searchy} alt="" /> </div>
        </div>
        <div className="board boardy">
            <div className="recent">
            <h4>Recent Payments</h4>
            {/* <p>See more</p> */}
            </div>
            <div className='mith'>
                <div className='manny'><img src={manny} alt="" /> </div>
                <p>N3000</p>
                <div className="pyramid"><img src={client} alt="" /> </div>
                <p>30s ago</p>
            </div>
            <div className='mith'>
                <div className='manny'><img src={manny} alt="" /> </div>
                <p>N3000</p>
                <div className="pyramid"><img src={client} alt="" /> </div>
                <p>30s ago</p>
            </div>
            <div className='mith'>
                <div className='manny'><img src={manny} alt="" /> </div>
                <p>N3000</p>
                <div className="pyramid"><img src={client} alt="" /> </div>
                <p>30s ago</p>
            </div>
            <div className='mith'>
                <div className='manny'><img src={manny} alt="" /> </div>
                <p>N3000</p>
                <div className="pyramid"><img src={client} alt="" /> </div>
                <p>30s ago</p>
            </div>
            <div className='mith'>
                <div className='manny'><img src={manny} alt="" /> </div>
                <p>N3000</p>
                <div className="pyramid"><img src={client} alt="" /> </div>
                <p>30s ago</p>
            </div>
            <div className='mith'>
                <div className='manny'><img src={manny} alt="" /> </div>
                <p>N3000</p>
                <div className="pyramid"><img src={client} alt="" /> </div>
                <p>30s ago</p>
            </div>
            <div className='mith'>
                <div className='manny'><img src={manny} alt="" /> </div>
                <p>N3000</p>
                <div className="pyramid"><img src={client} alt="" /> </div>
                <p>30s ago</p>
            </div>


        </div>
        <div className="numy">
            <p className='nims'>1</p>
            <p className='nims'>2</p>
            <p className='nims'>3</p>
            <p className='nims'>4</p>
            <p className='nims'>5</p>
        </div>
    </div>
  )
}

export default Addtrans