import React from 'react'
import sach from "../assets/sach.svg"
import access from "../assets/access.svg"
import accessone from "../assets/accessone.svg"
import accesstwo from "../assets/accesstwo.svg"
import accessthree from "../assets/accessthree.svg"
import backarr from "../assets/backarr.svg"
import logline from "../assets/logline.svg"
// import backarr from "../assets/backarr.svg"
import { useNavigate } from 'react-router-dom'

const Banker = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
      // Navigate to the next component route
      navigate('/Bonustwo'); // Replace '/next-component' with the actual route you want to navigate to
    };
  return (
    <div>
        <div className="wrody">
         <div className="transfer">
            <div className="backarrow"><img src={backarr} alt="" /> </div>
            <h4>Select bank</h4>
         </div>
         <div className="emans">
            <div className="sach"><img src={sach} alt="" /> </div>
            <input type="text" id="email" name="firstName" className='' placeholder='Search bank name'/>

         </div>
         <div className="login">
            <div className="logline"> <img src={logline} alt=""></img></div>
            <p className='log'>Or</p>
            <div className="logline"> <img src={logline} alt=""></img></div>
            
        </div>
        <div className="bankol">
            <div className="access">
                <img src={accessone} alt="" />
                <p>Access Bank</p>
            </div>
            <div className="access">
                <img src={access} alt="" />
                <p>First Bank</p>
            </div>
            <div className="access">
                <img src={accesstwo} alt="" />
                <p>Union Bank</p>
            </div>
            <div className="access">
                <img src={accessthree} alt="" />
                <p>Stanic Bank</p>
            </div>
 

        </div>
        <button
              type="submit"
              className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-full mb-4 mt-10"
              onClick={handleButtonClick}
            >
              Next
            </button>
            </div>
    </div>
  )
}

export default Banker
