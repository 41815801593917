import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import ham from "../assets/ham.svg"
import cross from "../assets/cross.png"
import { Link } from 'react-router-dom';
// import { bubble as Menu } from 'react-burger-menu';

const CustomBurgerIcon = () => <img src={ham} className='hamy'/>;
const MobileBar = () => {
  return (
    <div className='block sm:hidden'>
      <Menu className='menu fixed right-0 w-full h-full' customBurgerIcon={<img src={ham} className='hamy'/>} customCrossIcon={<img src={cross} className='cross'/>}>
      <Link to="/" id="home" className="menu-item" >Home</Link>
      <Link to="/Contact" id="home" className="menu-item" href="/Contact">Contact Us</Link>
      <Link id="home" className="menu-item" to="/SignUp">Become a Vendor</Link>
      <Link id="home" className="menu-item" to="/">Buy Electricity</Link>
      
    </Menu>
    </div>
  );
};

export default MobileBar;