import React from 'react'
import Bonus from "../components/Bonus"
import AdminSideBar from '../components/AdminSidebar'
import DashHeader from '../components/DashHeader'

const AddBonus = () => {
  return (
    <div>
         <div className="instant">
        <AdminSideBar/>
        <div className="panel">
          <DashHeader/>
         
          <Bonus/>
          

        </div>
      </div>
    </div>
  )
}

export default AddBonus