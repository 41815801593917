export const environment = {
   // api: 'http://localhost:4000/api/v1',
     api: 'https://instantpower-api.onrender.com/api/v1'
}
export const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "green",
};
export const publicKey = 'pk_test_fab8cec65541b519eff779b50da0388bf6152452';
