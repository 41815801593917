import React from 'react'
import instaa from "../assets/instaa.svg";
import twitter from "../assets/twitter.svg";
import linkedin from "../assets/linkedin.svg";
import liny from "../assets/liny.svg";
import phone3 from "../assets/facebook.svg";
import longline from "../assets/longline.svg";
import warn from "../assets/warning.svg"
import copy from "../assets/copy.svg"
import logline from "../assets/logline.svg"

const Sharim = () => {
  return (
    <div>
        <div className="boxe">
    <div className="next flex mt-16">
        <div className="warn mr-4">
            <img src={warn} alt="" />
        </div>
        <div className="top mb-8 text-[#000]">Get N100 or more on your next recharge for every friend you invite to Top-up. Use the link below</div>
    </div>
    <div className="nexty flex justify-center m-auto w-full  bg-[#E6F6F4] items-center h-16">
        
        <div className="topy text-[#0CAE18]">http:// Instantpower 639h?jn8u3u4-0132</div>
        <div className="warny">
            <img src={copy} alt="" />
        </div>
        </div>
        <p className='text-[#808080] text-center mt-4'>Click link to copy</p>
        <div className="login">
            <div className="logline"> <img src={logline} alt=""></img></div>
            <p className='log'>Share</p>
            <div className="logline"> <img src={logline} alt=""></img></div>
            
        </div>
        <div className="soca flex justify-center mt-16">
        <div className="solo">
                <img src={instaa} alt="" />
             </div>
             <div className="solo">
                <img src={twitter} alt="" />
             </div>
            
             <div className="solo">
                <img src={phone3} alt="" />
             </div>

             </div>
        
    </div>
    </div>
  )
}

export default Sharim