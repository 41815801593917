import React from 'react';
import call from "../assets/call.svg"
import logo from "../assets/logo.svg"
import { Link } from 'react-router-dom';
import MobileBar from "../components/MobileBar"
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-modal';

// import { slide as Menu } from 'react-burger-menu';


const Nav = ({ isValidPhoneNumber }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phoneNum, setPhoneNum] = useState("")
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  }

  return (
    <div className='hidde'>
      <header className="w-full">
        <nav className="navy">

          <Link to="/">
            <div className="logosm">
              <img className="" src={logo} alt="Logo" />
            </div>
          </Link>
          <div className=" hope hidden sm:block">
            <Link to="" className="text-[#1B1B1B] hover:bg-yellow-300 hover:text-black px-3 mr-3 py-2 rounded-md text-sm font-medium help">About Us</Link>
            <Link to="/Contact" className="text-[#1B1B1B] hover:bg-yellow-300 hover:text-black px-3 mr-3 py-2 rounded-md text-sm font-medium help">Contact Us</Link>
            <Link to="/Signup" className="text-[#1B1B1B] hover:bg-yellow-300 hover:text-black px-3 mr-3 py-2 rounded-md text-sm font-medium help">Purchase Commissions</Link>

            <button
              type="submit"
              variant="primary"
              onClick={() => setIsModalOpen(true)}

              className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-4 px-6 rounded "
            >
              Buy Electricity
            </button>
          </div>

          <MobileBar className="" />

        </nav>

      </header>
      {/* <Modal
        isOpen={isModalOpen}
        onRequestClose={toggleModal}
        contentLabel='Example Modal'
        className={`bg-transparent`}
        style={{
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "hsla(0, 0%, 0%, 0.8)",
            zIndex: 999,
          }
        }}
      >
        <div className='modal'>
          <div className='modal-content'>
            <div className='close mt-20'>
              <button className='modbtn' onClick={() => setIsModalOpen(false)} style={{ cursor: "pointer" }}>X

              </button>
              <h2 className='fast'>It's simple and Fast</h2>
              <div className="text-center w-full justify-between m-auto">
                <div className='w-4/5 m-auto justify-between mt-40 meter'>
                  <div className='w-2/5 entry'>
                    <input
                      type="text"
                      className='green text[#3DBE46] w-full'

                      placeholder="Enter your phone number to begin"
                      onChange={(e) => setPhoneNum(e.target.value)}
                    />
                  </div>
                  <div className='w-1/5 griny'>
                    <a onClick={() => isValidPhoneNumber(phoneNum)}><button
                      type="submit"
                      className="bg-[#0c0cb5] hover:bg-[#22334d] text-white font-bold py-2 px-4 rounded w-full "
                    >
                      Buy Electricity
                    </button>
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Modal> */}



    </div>
  );
};

export default Nav;
