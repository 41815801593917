import React from 'react'

const Change = () => {
  return (
    <div>
        <div className="boxe">
         <form action='' className='forming'>
         <div className="email">
        <label htmlFor="mail" className='mail'>Current Password</label><br />
        <input type="email" id="email" name="email" className='emaili' placeholder='Enter Meter Number'/>
        </div>

        
        <div className="email">
        <label htmlFor="mail" className='mail'>New Password</label><br />
        <input type="email" id="email" name="email" className='emaili' placeholder='Must contain 8 characters'/>
        </div>
        <div className="email">
        <label htmlFor="mail" className='mail'>Confirm Password</label><br />
        <input type="email" id="email" name="email" className='emaili' placeholder='Enter Password again'/>
        </div>
       
      
       
        
        <button
              type="submit"
              className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-full mb-4"
            >
              Submit Request
            </button>
        </form>

      

         </div>
    </div>
  )
}

export default Change