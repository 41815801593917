import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import transfer from "../assets/transfer-dark.jpg"
export default function SectionCard() {
    return (
        <div className="w-100 relative isolate overflow-hidden bg-[#C4D8E9] px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
            <div className="absolute w-100 inset-0 -z-10 overflow-hidden">
                <svg
                    className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                            width={200}
                            height={200}
                            x="50%"
                            y={-1}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                        <path
                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    {/* line on the background image */}
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                </svg>
            </div>
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                        <div className="lg:max-w-lg">
                            <p className="text-base font-semibold leading-7 text-indigo-600">Online Transaction</p>
                            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Pay Via Bank Transfer</h1>
                            <p className="mt-6 text-xl leading-8 text-gray-700">
                                Switch to bank transfers for instant, hassle-free electricity
                                top-ups. Stay focused while we keep your power flowing
                                smoothly.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="-ml-12 mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                    <img
                        className="h-[500px] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] object-contain"
                        src={transfer}
                        alt=""
                    />
                </div>
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                        <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                            <p className='text-gray-700'>
                                Instantaneous Top-ups: Bank transfers offer instant processing, ensuring that your electricity top-ups are completed promptly. No more waiting in long queues or dealing with delays in payment processing. Enjoy the convenience of immediate replenishment of your electricity credits whenever you need it.
                            </p>

                            <p className="mt-8 text-gray-700">
                                Convenient Accessibility: Bank transfers provide unmatched convenience, allowing you to initiate payments from the comfort of your home or on the go using your smartphone. Whether you're managing your finances during office hours or late at night, bank transfers offer round-the-clock accessibility to ensure that your electricity needs are always met.
                            </p>
                            <p className="mt-6 text-gray-700">
                                Cost-Effective Solution: Bank transfers often come with minimal or no transaction fees, making them a cost-effective solution for managing your electricity payments. Save money on additional charges associated with other payment methods and allocate your funds towards more important expenses.
                            </p>
                            <a href="#dan">
                                <button
                                    type="submit"
                                    className="bg-[#0CAE18] hover:bg-[#224d25] text-white font-bold py-2 px-4 rounded w-2/5 mt-4"
                                >
                                    Buy Electricity
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
