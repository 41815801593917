import React from 'react'
import wallet from "../assets/wallet.svg"
import naira from "../assets/naira.svg"
import eye from "../assets/eye.svg"
import dollar from "../assets/dollar.svg"
import rate from "../assets/rate.svg"
import downarr from "../assets/downarr.png"
import manny from "../assets/manny.svg"
import client from "../assets/client.svg"
import SettyCal from  '../components/SettyCal'
import { useState } from 'react'
import "../components/Admin.css"




const Dashy = () => {
    const [isCalendarVisible , setCalendarVisible] = useState(false);

    const toggleCalendar = () => {
      setCalendarVisible(!isCalendarVisible);
    }
    console.log('isCalendarVisible:', isCalendarVisible);
   

  return (
    <div>
        <div className="balance">
            <div className="bonus">
                <div className="wallet"><img src={wallet} alt="" /> </div>
                <div className="upper">
                <p>Bonus Balance</p>
                <div className="see">
                    <p className='lex'><span className=''><img src={naira} alt="" /> </span>0.00</p>
                    <div className="eye"><img src={eye} alt="" /> </div>
                </div>
                </div>
            </div>
            <div className="total">
                <div className="dollar"><img src={dollar} alt="" /> </div>
                <div className="paying">
                    <p>Total Payment</p>
                    <p className='twenty'><span className=''><img src={naira} alt="" /> </span>20,000</p>
                </div>
            </div>
        </div>
        <div className="monthly">
            <div className="month">
                <div className="cal" onClick={toggleCalendar}><img src={rate} alt="" /> </div>
                <p className='this'>This month</p>
                <div className="down">
                    <img src={downarr} alt="" />
                </div>
                </div>
    
        </div>
        <div className="board">
            <div className="recent">
            <h4>Recent Payments</h4>
            <p>See more</p>
            </div>
            <div className='mith'>
                <div className='manny'><img src={manny} alt="" /> </div>
                <p>N3000</p>
                <div className="pyramid"><img src={client} alt="" /> </div>
                <p>30s ago</p>
            </div>
            <div className='mith'>
                <div className='manny'><img src={manny} alt="" /> </div>
                <p>N3000</p>
                <div className="pyramid"><img src={client} alt="" /> </div>
                <p>30s ago</p>
            </div>
            <div className='mith'>
                <div className='manny'><img src={manny} alt="" /> </div>
                <p>N3000</p>
                <div className="pyramid"><img src={client} alt="" /> </div>
                <p>30s ago</p>
            </div>


        </div>


    </div>
  )
}

export default Dashy