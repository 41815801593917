import React from 'react'
import AddReq from '../components/AddReq'
import AdminSideBar from '../components/AdminSidebar'
import DashHeader from '../components/DashHeader'

const AddRequest = () => {
  return (
    <div>
           <div className="instant">
        <AdminSideBar/>
        <div className="panel">
          <DashHeader/>
         
          <AddReq/>
          

        </div>
      </div>
    </div>
  )
}

export default AddRequest